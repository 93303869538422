<template>
  <div>
    <div class="module-header" style="margin-bottom: 14px">趋势统计图</div>
    <div style="padding: 16px;">
      <stacked-column-chart
        :chart-data="chartData"
        chart-id="log-chart"
        :size="size"
        :height="280"
        :show-legend="false"
        colorType="date"
        :axis="{ x: 'date', y: 'value' }"
        :colors="['#247ddd']"
      ></stacked-column-chart>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import StackedColumnChart from '@/components/chart/StackedColumnChart.vue'

export default {
  name: 'LogChartCard',
  props: {
    getFunc: {
      type: Function
    },
    datetimeRange: {
      type: Array
    },
    source: {
      type: String
    }
  },
  components: {
    StackedColumnChart
  },
  data () {
    return {
      chartData: []
    }
  },
  computed: {
    size () {
      return 20
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      if (this.getFunc) {
        const params = {
          datetime_from: this.datetimeRange[0].format('YYYY-MM-DD HH:mm:ss'),
          datetime_to: this.datetimeRange[1].format('YYYY-MM-DD HH:mm:ss')
        }
        if (this.source) params.source = this.source
        this.getFunc(params).then(res => {
          if (res.data.data.length) {
            this.chartData = res.data.data.map(item => {
              return {
                date: moment(item.timestamp).format('MM-DD HH:mm:ss'),
                value: item.value
              }
            })
          } else this.chartData = []
        })
      }
    }
  },
  watch: {
    datetimeRange () {
      this.fetch()
    }
  }
}
</script>
