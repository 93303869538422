<template>
  <div>
    <a-table
      class="custom-table small-custom-table"
      :columns="columns"
      row-key="id"
      :loading="loading"
      :scroll="{ x: scrollX }"
      :data-source="dataSource"
      :pagination="false"
    >
      <div
        slot="filterDropdown"
        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="c => (searchInput = c)"
          :placeholder="`搜索 ${column.title}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block;"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
        />
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          搜索
        </a-button>
        <a-button size="small" style="width: 90px" @click="handleReset(clearFilters, column.dataIndex)">
          重置
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="timestamp" slot-scope="text">{{ moment(text).format('YYYY-MM-DD HH:mm:ss') }}</template>
    </a-table>
    <a-pagination
      :current="params.page"
      :pageSize="params.page_size"
      :show-size-changer="true"
      :show-total="t => `共 ${t} 条`"
      :total="total"
      @change="
        page => {
          params.page = page
          fetch()
        }
      "
      @showSizeChange="
        (_, pageSize) => {
          params.page = 1
          params.page_size = pageSize
          fetch()
        }
      "
      style="float: right; margin-top: 16px;"
    ></a-pagination>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'LogTable',
  props: {
    datetimeRange: {
      type: Array
    },
    getFunc: {
      type: Function
    },
    columns: {
      type: Array
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  },
  data () {
    return {
      searchInput: null,
      total: 0,
      dataSource: [],
      loading: false,
      params: {
        page: 1,
        page_size: 20,
        match: {}
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    moment,
    fetch () {
      const params = {
        page: this.params.page,
        page_size: this.params.page_size,
        datetime_from: this.datetimeRange[0].format('YYYY-MM-DD HH:mm:ss'),
        datetime_to: this.datetimeRange[1].format('YYYY-MM-DD HH:mm:ss'),
        match: this.params.match
      }
      this.loading = true
      this.getFunc(params).then(res => {
        this.dataSource = res.data.data
        this.total = res.data.total
        this.loading = false
      })
    },
    handleSearch (selectedKeys, confirm, dataIndex) {
      const v = selectedKeys[0]
      if (v.replace(/\s+/g, '') !== '') {
        this.params.match[dataIndex] = selectedKeys[0]
        this.params.page = 1
        this.fetch()
        confirm()
      }
    },
    handleReset (clearFilters, dataIndex) {
      if (Object.hasOwn(this.params.match, dataIndex)) {
        delete this.params.match[dataIndex]
        this.params.page = 1
        this.fetch()
      }
      clearFilters()
    }
  },
  watch: {
    datetimeRange () {
      this.params.page = 1
      this.fetch()
    }
  }
}
</script>
