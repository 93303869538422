<template>
  <div>
    <div style="float: right">
      <datetime-range
        id="custom-datetime-range"
        :allow-clear="false"
        :default-value="datetimeRange"
        @ok="
          v => {
            datetimeRange = v
          }
        "
      ></datetime-range>
    </div>
    <div style="clear: both"></div>
    <div
      class="module-container"
      style="height: 370px; margin: 16px 1px 0 1px"
    >
      <log-chart-card :get-func="getTrendFunc" :datetime-range="datetimeRange"></log-chart-card>
    </div>

    <div class="module-container" style="min-height: 400px; margin: 16px 1px 0 1px">
      <div class="module-header" style="margin-bottom: 14px">
        详细日志表
      </div>
      <log-table :datetime-range="datetimeRange" :columns="columns" :get-func="getTableFunc"></log-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { getNginxAccessLogList, getNginxAccessLogTrend } from '@/api/log'
import DatetimeRange from '@/components/DatetimeRange'
import LogChartCard from './modules/LogChartCard.vue'
import LogTable from './modules/LogTable.vue'

export default {
  name: 'NginxLog',
  components: {
    DatetimeRange,
    LogChartCard,
    LogTable
  },
  data () {
    return {
      datetimeRange: [moment().subtract(1, 'hour'), moment()],
      getTrendFunc: getNginxAccessLogTrend,
      getTableFunc: getNginxAccessLogList,
      columns: [
        {
          dataIndex: 'timestamp',
          title: '采集时间',
          width: 200,
          scopedSlots: {
            customRender: 'timestamp'
          }
        },
        {
          dataIndex: 'hostip',
          title: 'IP 地址',
          width: 160,
          key: 'hostip',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'hostip'
          }
        },
        {
          dataIndex: 'remote_addr',
          title: '请求地址',
          width: 180,
          key: 'remote_addr',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'remoteAddr'
          }
        },
        {
          dataIndex: 'time_local',
          title: '请求时间',
          width: 160,
          scopedSlots: {
            customRender: 'timeLocal'
          }
        },
        {
          dataIndex: 'http_host',
          title: '域名',
          width: 160,
          key: 'http_host',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'httpHost'
          }
        },
        {
          dataIndex: 'request_method',
          title: '请求方式',
          width: 120,
          key: 'request_method',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'requestMethod'
          }
        },
        {
          dataIndex: 'request_uri',
          title: '请求路径',
          width: 200,
          key: 'request_uri',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'requestUri'
          }
        },
        {
          dataIndex: 'status',
          title: '状态码',
          width: 120,
          key: 'status',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'status'
          }
        },
        {
          dataIndex: 'body_bytes_sent',
          title: '发送字节数',
          width: 120,
          key: 'body_bytes_sent',
          scopedSlots: {
            customRender: 'bodyBytesSent'
          }
        },
        {
          dataIndex: 'http_user_agent',
          title: '客户端代理',
          width: 240,
          key: 'http_user_agent',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'httpUserAgent'
          }
        },
        {
          dataIndex: 'upstream_addr',
          title: '响应地址',
          width: 160,
          key: 'upstream_addr',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'upstreamAddr'
          }
        },
        {
          dataIndex: 'upstream_response_time',
          title: '响应时间',
          width: 120,
          scopedSlots: {
            customRender: 'upstreamResponseTime'
          }
        },
        {
          dataIndex: 'request_time',
          title: '请求耗时',
          width: 120,
          key: 'request_time',
          scopedSlots: {
            customRender: 'requestTime'
          }
        }
      ]
    }
  }
}
</script>

<style lang="less">
.module-container {
  position: relative;
  background: #fff;
  border-radius: 6px;
  padding: 16px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
}
</style>
